import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoMenu } from '../../component'
import { setSidebarLeft } from '../../layout/redux/AppActions'
import App from './app.json'
import Logo from '../img/logo-beetech-1-b.png'

import './website.css'
import { history } from '../../helpers'
import { useLocation } from 'react-router-dom'

export default () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [locationPage, setLocationPage] = useState('home')
  const sidebar = useSelector(state => state.appState)

  const handleScroll = (e) => {
    const elem = document.getElementById(e)
    if (elem) { 
      setLocationPage(e)
      elem.scrollIntoView() 
    }
  }

  return (
    <header id='website-navbar-default'>
      
      <div id='website-navbar-brand' onClick={()=> handleScroll('home')}>
        <img className='website-logo' src={Logo} title={App.titulo}/>
      </div>
      <div className={`box-menu ${sidebar.sidebarLeft ? 'open' : ''}`}>
        {
          location.pathname === '/' ?
          App.menu.map(e => (
            <button key={e.id}
              className={`btn-navbar ${ locationPage === e.go ? 'active' : '' }`}
              onClick={() => [history.push('/'), handleScroll(e.go)]}
            >
              {e.label}
            </button>
          ))
          :
          <button
          className={`btn-navbar ${ locationPage === 'home1' ? 'active' : '' }`}
          onClick={() => [history.push('home'), setLocationPage('home')]}
          >
            Home
          </button>
        }
      </div>
      <button
        className={sidebar.sidebarLeft ? 'btn-menu open' : 'btn-menu'}
        onClick={() => dispatch(setSidebarLeft(!sidebar.sidebarLeft))}
      >
        <IcoMenu />
      </button>
    </header>
  )
}
