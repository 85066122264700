import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-carousel-minimal';
import About from '../about/about'
import Solutions from '../solutions/solutions'
import Clients from '../clients/clients'
import Contact from '../contact/contact'
import './home.css'
import ParticlesBg from "particles-bg";

import imagem1 from '../img/bg-banner-1.png'
import imagem2 from '../img/bg-banner-2.png'
import imagem3 from '../img/bg-banner-3.png'

export default () => {

  const carousel = [
    {
      caption: `<div class='box-info'><h2><span class='text-yellow'>Build, don\'t sell</span></h2>`,
      title: 'Build, don\'t sell',
      description: ``,
      image: imagem1
    },
    {
      caption: `<div class='box-info'><h2>We <span class='text-yellow'>believe</span> in the power of <span class='text-yellow'>communities</span>.</h2></div>`,
      title: 'We believe in the power of communities',
      description: ``,
      image: imagem2
    },
    {
      caption: `<div class='box-info'><h2>That\'s why <span class='text-yellow'>building</span> them is our mission!</h2></div>`,
      title: 'That\'s why building them is our mission!',
      description: ``,
      image: imagem3
    }
  ]

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (<>
    <div className='website-home' id='home'>
    {/* <ParticlesBg type="circle" bg={true} /> */}
      <Carousel
            data={carousel}
            time={5000}
            width="100%"
            height="calc(100vh - 95px)"
            captionStyle={captionStyle}
            radius="0"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="top"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={false}
            thumbnailWidth="100px"
          />
      
    </div>
    <About />
    <Solutions />
    <Clients />
    <Contact />
  </>
  )
}
