import React, { useEffect, useRef, useState } from 'react'
import '../../index.css'
import './contact.css'
import emailjs from 'emailjs-com'
import { AddAlert } from '../../component'
import { useDispatch } from 'react-redux'
import Logo from '../img/logo-beetech-1.png'
import LogoLinkedin from '../img/logo-linkedin.png'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export default (props) => {
  const dispatch = useDispatch()
  const [{ name, email, message }, setState] = useState(initialState)
  const refContact = useRef()

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_m77opoq', 'template_fxlw6br', e.target, 'user_NrstmHaIrZCVJrCJKVcJn'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          dispatch(AddAlert('success', 'Your message has been sent successfully.'))
        },
        (error) => {
          console.log(error.text)
        }
      );
      e.target.reset()
  }
  return (
    <div>
      <div id='contact' className='website-contact' ref={refContact}>
        <div className='content-container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  If you want to know more about how we can support you, contact us using the form below.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-4 contact-info'>
            <div className='contact-item'>
            <img className='website-logo' src={Logo}/>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <strong>Beetech Labs Ltd.<br/>UK Registered Company</strong>                  
                </span>                
              </p>
              <p>
                <span>
                  Address
                </span>
                71-75 Shelton Street, London - Greater London
                WC2H 9JQ - United Kingdom
              </p>
            </div>
            <div>
                Connect with us on <br/>
                <a href="https://www.linkedin.com/company/beetechlabs/" target='_blank'><img className='website-logo' src={LogoLinkedin}/></a>
            </div>
          </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}
