import React from 'react'
import './solutions.css'
import SolutionsChart from '../img/solutions-chart.png'

export default () => {

  return (<>
    <div className='website-solutions' id='solutions'>
      <div className="content-container">
        <div className='section-title'>
          <h2>Solutions</h2>
          <p>
            We are a digital business with one single motto: Build, don't sell!
          </p>
        </div>
        <div className='row'>
          <div className='section-chart'>
            <img className='website-solutions-chart' src={SolutionsChart}/>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
