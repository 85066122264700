import React, { useEffect, useState } from 'react'
import './purpouse.css'
import '../../index.css'
import PurposeImg from '../img/purpose.png'
import Contact from '../contact/contact'



export default () => {
  return (<>
    <div id='purpose' className='website-purpose'>
      <div className='content-container'>
        <div className='section-title'>
          <h2>Purpose</h2>
          <p>
          Our vision is to develop people-centred technology systems and a toolbox of innovative business growth solutions.

We support you to bridge human resources, technology and investments for people and the planet in an empowering and decentralised way.

We support clients by building their online communities and bridging them with resources and digital technologies including platforms and e-commerce.
<br/>
          <div className='img-align'>
            <img src={PurposeImg} className='purpose-img'/>
          </div>
          <br/>
          We will never be able to achieve our mission and vision without building and nurturing solid partnerships. Partnerships will help us sell products or services and develop and deliver Kingdom media & technology solutions.
          We offer our partners opportunities in three main areas:
          <ul>
            <li>crowdsourcing (team-up with us, becoming a vendor or technology provider),</li>
            <li>crowdfunding (invest in all or any single project) and;</li>
            <li>co-ownership (become a director or a shareholder).</li>
          </ul>
          Each product and platform will generate revenue streams through sponsorships or affiliated programs, advertising, or direct sales of products (e-commerce). If you want to partner with us, send us a message below.
          </p>
        </div>
      </div>
    </div>
    <Contact />
  </>
  )
}
