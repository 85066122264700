import React, { useEffect, useState } from 'react'
import './about.css'
import '../../index.css'
import AboutImg from '../img/about-us.png'
import { Button } from '../../component'
import { history } from '../../helpers'

export default () => {
  return (<>
    <div className='website-about' id='about'>
      <div className="content-container">
        <div className='row'>
          <div className="col-xs-12 col-md-6">
            <img src={AboutImg}/>
          </div>
          <div className="col-xs-12 col-md-6">
            <h2>About Us</h2>
            <p>We help entrepreneurs and businesses to build and grow their online communities. Our team develops the strategy, sources the technology, and delivers the best digital systems to engage and grow your audience and business. <br/>

We passionately believe that technology should serve people and the planet and not the other way round. That is the reason why our focus is on building communities and not transactional selling. Our clients have moved away from the commoditised economy to a more social economy. <br/>

Our vision is to enable people to live better lives, work, deliver value, trade, invest and care for our planet and future.
</p>
            
            <div className='purpose-action'>
              <Button color='primary' variant='normal' size='block' action={() => history.push('purpose')}>
                Purpose
              </Button>
            </div>

            <h3>Why Choose Us?</h3>
            <div className='row'>
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <ul>
                  <li>A team of global digital entrepreneurs and developers</li>
                  <li>30 plus years of experience in enterprise development</li>
                  <li>A proven track record in our professional careers</li>
                  <li>We are fully committed to people and the planet</li>
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <ul>
                  <li>A multicultural and inclusive approach to community development - we are a people-first business</li>
                  <li>Yes, we are nerds too, but we are fun to work with!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
