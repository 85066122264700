import React, { useEffect, useState } from 'react'
import './clients.css'
import '../../index.css'
import LogoMomentum from '../img/logo-momentum.png'
import LogoLeading360 from '../img/logo-leading360.png'
import LogoTreeOfLife from '../img/logo-tree-of-life.png'


export default () => {
  return (<>
    <div id='clients' className='website-clients'>
      <div className='content-container'>
        <div className='section-title'>
          <h2>Brands & Communities</h2>
          <p>
          </p>
        </div>
        <div id='row' className='website-clients'>
          <div className='website-group'>
            <img src={LogoMomentum} className='clients-img' />
            <div className='caption'>
              <h4>Momentum</h4>
              <p>The Resilient Community</p>
            </div>
          </div>
          <div className='website-group'>
            <img src={LogoLeading360} className='clients-img' />
            <div className='caption'>
              <h4>Leading 360</h4>
              <p>Leadership Diagnostic and Empowering</p>
            </div>
          </div>
          <div className='website-group'>
            <img src={LogoTreeOfLife} className='clients-img' />
            <div className='caption'>
              <h4>Tree of Life</h4>
              <p>E-commerce</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
