import React from 'react'

export default () => {
  return <footer id='box-footer'>
    <div className='container text-center'>
          <p>
            &copy; 2021 Beetech Labs. All rigths reserved.
            
          </p>
        </div>

  </footer>
}
