import React, { useEffect } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { history } from './helpers'

import PublicLayout from './website/website/website'
import WebsiteHome from './website/home/home'
import WebsiteAbout from './website/about/about'
import WebsiteSolutions from './website/solutions/solutions'
import WebsiteClients from './website/clients/clients'
import WebsiteContact from './website/contact/contact'
import WebsitePurpose from './website/purpose/purpose'

const PublicRoute = ({ component: Component, path }) => {
  return (
    <Route
      exact
      path={path}
      render={props =>
        <PublicLayout>
          <Component {...props} />
        </PublicLayout>
      }
    />
  )
}

export default () => {
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute path='/' exact={true} component={WebsiteHome} />
        <PublicRoute path='/about' exact={true} component={WebsiteHome} />
        <PublicRoute path='/solutions' exact={true} component={WebsiteHome} />
        <PublicRoute path='/clients' exact={true} component={WebsiteHome} />
        <PublicRoute path='/contact' exact={true} component={WebsiteHome} />

        <PublicRoute path='/purpose' exact={true} component={WebsitePurpose} />
        <Redirect from='*' to='/' />
        <Route render={() => <h1>NotFound</h1>}/>
      </Switch>
    </Router>
  )
}
