import { combineReducers } from 'redux'
import alerts from './component/alert/alertsRedux'
import appState from './layout/redux/AppReducer'

export const rootReducer = combineReducers({
  alerts,
  appState
})

export default rootReducer
