import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Header from './header'
import Footer from './footer'
import { Alert } from '../../component'
import './website.css'
import './app-color.css'

export default ({ children }) => {
  const [heigthHeader, setHeigthHeader] = useState(0)
  useEffect(() => {
    setHeigthHeader(document.getElementById('website-navbar-default').offsetHeight)
    setTimeout(() => {
      setHeigthHeader(document.getElementById('website-navbar-default').offsetHeight)
    }, 1000);
  }, [])
  const heightApp = { height: `calc(100vh - ${heigthHeader}px)` }

  return (
    <>
      <Alert />
      <Header />
      <div id='box-app' style={heightApp}>
        <section id='box-container' style={heightApp}>
          <div>
            {children}
          </div>
          <Footer />
        </section>
      </div>
    </>
  )
}
